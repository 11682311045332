import React, { useEffect } from 'react'
import '../stylesheets/components.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AOS from "aos";
import "aos/dist/aos.css";

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
export default function ProgHigh(props){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
    return(
   
            <Grid container className="spacing" spacing={6}>
                <Grid item md={6} xs={12}>
                    <img src={props.imageURL} className="imageText" data-aos="fade-up"/>
                </Grid>
                
                <Grid className="progHighlight" item md={6} xs={12}>
                    <div class="titleText">{props.title}</div>
                    <div class="subtitleText">

                    <strong> Adaptive program: </strong>
                    <ul>- Beginner level (Level 1): Sep-Dec</ul>
                    <ul>- Mid level (Level 2): Jan-Apr</ul>
                    <ul>- Advanced (Level 3): May-Jun</ul>
                    <strong> Flexible Enrollment and fees​ </strong>
                    <ul>- Modules are offered independently </ul>
                    <ul>- Fees are accessed per module (discount applies for multiple modules) </ul>
                    
                    
                    </div>
                    
                </Grid>
            </Grid>
      
    )
}