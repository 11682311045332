import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'
import '../stylesheets/components.css'


const slides = [
  { id: 0, url: 'photo-1548131089-d5d36b219767?ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80' },
]

export default function Image(){
  const [index, set] = useState(0)

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 0.65},
    leave: { opacity: 0 },
    config: config.molasses,
  })
  
 

  return transitions.map(({ item, props, key }) => (
    
    <animated.div
      key={key}
      class="bgOne"
      style={{ ...props, backgroundImage: `url(https://images.unsplash.com/${item.url}&auto=format&fit=crop)` }}
    />
    
 
  ))
}

