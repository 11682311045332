import React from "react";
import Image from "../components/Image"
import TextInImage from "../components/textInImage"
import ImageText from "../components/imageText"
import TextVideo from "../components/textVideo"
import { Container } from "@material-ui/core";
import Footer from "../components/footer";
import VideoText from "../components/videoText"


export default class LandingPage extends React.Component{
  render(){
    return(
      <div >
        <div className="background">
        < Image /> 
        < TextInImage /> 
        </div>

    <Container>
        < VideoText title="Introduction" 
        
          text="We want to help prepare our next generation for the next big thing, we unleash your child's potential, educate them on the latest technologies and trends. Exposing them to new technology inspires kids to challenge their potentials and be ready for the future." /> 
           < TextVideo title="Objectives" 
          
          text="Project-based learning, emphasis on teamwork, exposure to advanced technology, hands on and fun ways of learning is how we plan to enhance the learning experience." /> 
    </Container>

     




    </div>


   
    )
  }

}