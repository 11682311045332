import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { Grid } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import logo from "../images/circle.png";
import LinkedInIcon from '@material-ui/icons/LinkedIn';




const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '50vh',
    color: "white",
    fontSize: "18px !important",
    textAlign: "left",
   

  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor: "#333638",
    color: "white !important",
    padding: "30px"

  },
  con: {
    marginTop: "30px",
    marginBottom: "30px"
  },
  items:{
    fontSize: "12px",
    marginTop: "15px"
  },
  line:{
    marginTop: "60px",
    marginBottom: "20px",
    border: "1px solid #1e59ff"
  },
  sign:{
    fontSize: "12px",
    marginTop: "15px",
    marginBottom: "15px",
    textAlign: "right"
  }
}));
function Copyright(props) {

  return (
    <div style={{marginTop: "30px"}}>
      {props.title}
    </div>
  );
}



export default function StickyFooter() {
  const classes = useStyles();

  

  return (
    <div className={classes.title}>

      <div style={{marginTop: "140px"}}></div>

      <footer className={classes.footer}>
      
        
        
        <Grid container>


        <Grid item md={1} xs={12}>
        </Grid>

        <Grid item md={3} xs={12} >
          <a href="/"><img  src={logo} height="100" style={{marginTop: "50px"}} alt="Kat-Club-Logo"></img></a>

        </Grid>
        
          
          <Grid item md={2} xs={12}>
          
          

          <Copyright title="Follow Us"  />
          
          <Grid container style={{textAlign: "left", marginTop: "10px" }}>

            <Grid item md={2} xs={1}>
              <a style={{color: 'inherit'}} href="https://www.facebook.com/kidsadvancedtechclub">
                <FacebookIcon/>
              </a>                            
            </Grid>
            <Grid item md={2} xs={1}>
            <a style={{color: 'inherit'}} href="https://www.linkedin.com/in/kat-club-777536203/">
              <LinkedInIcon/>
            </a>
            
            </Grid>
            

          </Grid>

          

          </Grid>
          

          <Grid item md={2} xs={12}>
          <Copyright title="Contact Us"/>
          <div className={classes.items}>
            Email: support@katclub.ca
          </div>
          <div className={classes.items}>
            phone: 
            <br></br>250-212-9200 
            <br></br>778-801-9201
            <br></br> 778-807-7017
          </div>
          </Grid>


          <Grid item md={2} xs={12}>
          <Copyright title="Address" />
            <div className={classes.items}>
              Kelowna, BC, Canada
            </div>
          </Grid> 
          

        </Grid>



        <div className={classes.sign}>
              KAT Club
        </div>
       

     
      </footer>
    </div>
  );
}

