import React, { useState, useEffect } from 'react'
import '../stylesheets/components.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AOS from "aos";
import "aos/dist/aos.css";
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { WbIncandescentOutlined } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
	modal: {
	  display: 'flex',
	  alignItems: 'center',
	  justifyContent: 'center',

	},
	paper: {
	  backgroundColor: theme.palette.background.paper,
	  boxShadow: theme.shadows[5],
	  padding: theme.spacing(2, 4, 3),

	  borderRadius: '10px'
	},
  }));



export default function ImageText(props){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
    
	const classes = useStyles();

	const [open, setOpen] = useState(false);


	const handleOpen = () => {
		setOpen(true);
	  };
	
	  const handleClose = () => {
		setOpen(false);
	  };
    const handleClick = () => {
        window.open(props.readMore, '_blank');
        // window.location.href = props.readMore;        
    }
    return(
   
            <Grid container className="spacing" spacing={6}>
                <Grid item xs={12} md={6}>
                    <img src={props.imageURL} className="imageText3" data-aos="fade-up"/>
                </Grid>
                
                <Grid item xs={12} md={6}>
                    <div class="titleText">{props.title}</div>
                    <div class="subtitleText">{props.text}</div>
                    <div class="subtitleText">{props.academy}</div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className="buttonIntro"
                            onClick={handleClick}
                            endIcon={<KeyboardArrowRightIcon/>}
                        >
                            Read More
                        </Button>
                    </div>

                </Grid>



                <Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				className={`${classes.modal} system1`}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
				timeout: 500,
				}}
			>
				<Fade in={open}>
				<div className={`${classes.paper} paper1`}>
					<h2 id="transition-modal-title">{props.title}</h2>
					<p id="transition-modal-description">
                    <a href={props.readMore}>
                    Link
                    </a>                            
					</p>
				</div>
				</Fade>
			</Modal>
        </Grid>
      
    )
}