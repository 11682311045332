import React, { useState, useEffect } from 'react'
import { useTransition, animated, config } from 'react-spring'
import '../stylesheets/components.css'


const slides = [
  { id: 0, url: 'photo-1529390079861-591de354faf5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9' },
  { id: 1, url: 'photo-1518792528501-352f829886dc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9' },
  { id: 2, url: 'photo-1568585219057-9206080e6c74?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9' },
  { id: 3, url: 'photo-1589856198357-4cca29e342c7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9' },
]

export default function Image(){
  const [index, set] = useState(0)

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 0.65},
    leave: { opacity: 0 },
    config: config.molasses,
  })
  
  useEffect(() => void setInterval(() => set(state => (state + 1) % 4), 5000), [])

  return transitions.map(({ item, props, key }) => (
    
    <animated.div
      key={key}
      class="bg"
      style={{ ...props, backgroundImage: `url(https://images.unsplash.com/${item.url}&auto=format&fit=crop)` }}
    />
    
 
  ))
}

