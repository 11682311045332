import React, {useState, useEffect} from 'react';
import { withRouter } from 'react-router';
import { Container } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import RegisImage from "../components/RegisImage"
import CompTextWithin from "../components/textWithinComp"
import NavigatorForm from '../components/navigatorForm'



const ContactFormWeekend = (props) => {



    const buttonStyle = {
        marginTop: "20px",
        backgroundColor: "rgb(20, 150, 255)",
        outline: "none",
        padding: "10px",
        fontWeight: "500",
        color: "white"
    }

    const initialValues = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        studentSchool: '',
        module1end: false,
        module2end: false,
        module3end: false,
        module4end: false,
        formType: 'Weekend Form'
    }

    var [values, setValues] = useState(initialValues)

    const handleChange = e => {
        var { name, value } = e.target
        setValues({
            ...values, 
            [name]: value,
        })

    }

    const handleSubmit = e => {
        const templateId = 'template_lsrvipd';
        e.preventDefault();
   
        props.sendFeedback(templateId, {message_html: values.message, module_one_end: values.module1end, module_two_end: values.module2end, module_three_end: values.module3end, module_four_end: values.module4end, form_type: values.formType, phone_number: values.phone, student_schoolname: values.studentSchool, from_firstName: values.firstName, from_lastName: values.lastName, from_email: values.email });
        props.history.push(`/`);
    }


    return(
        <> 



<div  className="formArea">
    
            <Container>
            
                <form onSubmit={handleSubmit} className="formHere">
                <h4>Weekend Registration</h4>
                  <Grid container>
                    <Grid item md={6} xs={12} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}>First Name</label>
                        <input type="text" id="fname" name="firstName" placeholder="Student's First Name..."
                            value={values.firstName}
                            onChange={handleChange}
                        />
                    </Grid >

                    <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}> Last Name</label>
                        <input type="text" id="fname" name="lastName" placeholder="Student's Last Name..."
                            value={values.lastName}
                            onChange={handleChange}
                        />
                    </Grid >


                    <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}> Email Address</label>
                        <input type="email" id="email" name="email" placeholder="Student's Email..."
                            value={values.email}
                            onChange={handleChange}
                        />
                     </Grid >


                    <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Phone Number</label>
                        <input type="tel" id="phone" name="phone" placeholder="Phone..."
                            value={values.phone}
                            onChange={handleChange}
                        />
                     </Grid >

                     <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Grade</label>
                        <input type="text" id="studentSchool" name="studentSchool" placeholder="Student's Grade..."
                            value={values.studentSchool}
                            onChange={handleChange}
                        />
                     </Grid >

    
                     <Grid item md={6} xs={12} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Check Your Preferred Modules</label>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module1end" name="module1end" onChange={handleChange} value={true}/>
                            <label for="module1end"  className="labelCheck">Module 1: Robotics Saturday (11AM to 12:30PM)</label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module2end" name="module2end" onChange={handleChange} value={true}/>
                            <label for="module2end" className="labelCheck">Module 2: 3D Printing Saturday (1PM to 2:30PM)</label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module3end" name="module3end" onChange={handleChange} value={true}/>
                            <label for="module3end"  className="labelCheck">Module 1: Robotics Sunday (11AM to 12:30PM)</label>
                        </div>

                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module4end" name="module4end" onChange={handleChange} value={true}/>
                            <label for="module4end"  className="labelCheck">Module 2: Robotics Sunday (1PM to 2:30PM)</label>
                        </div>
              
              
               
                     </Grid >


        
                    <Grid item xs={12} style={{padding: "20px"}}>
                        <Button variant="contained" disabled={!values.email, !values.firstName} type="submit" disableElevation style={buttonStyle}>
                            Submit
                        </Button>
                    </Grid>
                    </Grid>
                   
                </form >
            </Container>
        </div>
        </>
    );
}

export default withRouter(ContactFormWeekend);