import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import BallotIcon from '@material-ui/icons/Ballot';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import MoreIcon from '@material-ui/icons/More';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles({
  list: {
    width: "100%"
  },
  fullList: {
    width: 'auto',
  },
});

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      

          < ListItem button component={Link}  to="/">
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText>Home</ListItemText>
          </ListItem>
    
      
        < ListItem button component={Link}  to="/programs">
          <ListItemIcon><BallotIcon /></ListItemIcon>
          <ListItemText>Programs</ListItemText>
        </ListItem>

              
        < ListItem button component={Link}  to="/registration/weekday">
          <ListItemIcon><NoteAddIcon /></ListItemIcon>
          <ListItemText>Registration</ListItemText>
        </ListItem>

{/*               
        < ListItem button component={Link}  to="/more">
          <ListItemIcon><MoreIcon /></ListItemIcon>
          <ListItemText>More</ListItemText>
        </ListItem>
       */}

      < ListItem button component={Link}  to="/about">
          <ListItemIcon><BallotIcon /></ListItemIcon>
          <ListItemText>About</ListItemText>
        </ListItem>

      </List>
    </div>
  );

  return (
    <div style={{zIndex: "1000", position: "absolute", marginTop: "20px", width: "95%"}}>
        <Grid container >
        <Grid item xs={9} style={{textAlign: "left", marginTop: "12px"}}>
            <div  >
                <Link to="/" className={'titleWhite'} >K.A.T. Club</Link>
            </div>
        </Grid>
        <Grid item xs={3}>

        {['right'].map((anchor) => (
            <React.Fragment key={anchor}>
            <IconButton onClick={toggleDrawer(anchor, true)} style={{color: "white"}}>< MenuIcon/></IconButton>
            <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
            >
                {list(anchor)}
            </SwipeableDrawer>
            </React.Fragment>
        ))}   
      </Grid>
      </Grid>

    </div>
  );
}