import React from "react";

import ContactForm from "../components/contactForm"
import emailjs from 'emailjs-com';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import RegisImage from "../components/RegisImage"
import CompTextWithin from "../components/textWithinComp"




const sendFeedback = (templateId, variables) => {
    emailjs.send('service_ad4syvb', templateId, variables, 'user_aCCkK6kuDVHKGvbqk')
        .then(res => {
          console.log(res, 'Email successfully sent!')
        })
        // Handle errors here however you like, or use a React error boundary
        .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
}


function Head() {

    return (
      <div >

    <div className="backgroundOne">
        < RegisImage /> 
        < CompTextWithin title = "Registration" />
        </div>

        
      
      </div>
    );
  }
  


export default function Registration(){
    let { url } = useRouteMatch();
    return(
        <div>


            < Head />
        
                
        
      
           
 
                <  Route  exact path={`${url}/weekday`} render={props => <ContactForm sendFeedback={sendFeedback} />} />

                
         
            
            
    
          
                
        </div>
    )

}