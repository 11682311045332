import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Link, useRouteMatch } from 'react-router-dom';


const useStyles = makeStyles({
    
    root: {
      width: "auto",
      marginTop: "15px"
    },
  });

export default function NavigatorForm(){
  let { url } = useRouteMatch();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    return(
        <div style={{textAlign: "center"}}>
                <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      showLabels
      className={classes.root}
    >
      <BottomNavigationAction component={Link} to={`${url}/weekday`} label="Registration" icon={<PlaylistAddCheckIcon />} />
      
      <BottomNavigationAction component={Link} to={`${url}/weekend`} label="Registration for Weekends" icon={<PlaylistAddCheckIcon />} />
     
    </BottomNavigation>
        </div>
    )
}