import React from "react";
import ProgContentImg from "../components/ProgContentImg";
import TextInImageTwo from "../components/textInImageTwo";
import ImageText from "../components/imageText";
import TextImage from "../components/textImage";
import Asd from "../components/elecproj";
import { Container } from "@material-ui/core";
import Footer from "../components/footer";


export default class ProgramContent extends React.Component{
  render(){
    return(
      <div >
        <div className="backgroundOne">
        < ProgContentImg /> 
        < TextInImageTwo /> 
        </div>

    <Container>

    < ImageText title="KIDS CODING" 
          imageURL="https://images.unsplash.com/photo-1603354350317-6f7aaa5911c5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80" 
          text="Expose kids into several programming languages. Languages include: Scratch, Swift, Python, and Java. Learn project-based assignment to develop animations. Learn combine modules to develop full-fledged games and apps. Learn to design a website." 
          readMore="Coding: For elementary level kids, students will learn how to develop various computer games in online scratch program. For middle school level kids, the students will be introduced to Python and Java language. For each case, step-by-step algorithm will be created to make a clear understanding of the logics behind the developed games and apps. For high school level kids, the students will be introduced to advanced level coding including the concept of big data, artificial intelligence, and machine learning."/> 
    < TextImage title="ROBOTICS" 
          imageURL="https://images.unsplash.com/photo-1550432163-9cb326104944?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80" 
          text="Learn program using Arduino boards and lego-robotics. Learn control algorithm. Build your own robot and program it for activities. Train your robot for problem-solving and to adapt to challenging conditions. Get ready for ultimate robo-games." 
          readMore="Robotics: KAT Club firm will provide a comprehensive experience of lego-robotics to kids through the use of Arduino boards. Lessons on control algorithms to develop and program different functions of robots will be provided. Also, hands-on-experience in training their robots to adapt to challenging conditions will be provided to kids. Booklets, short instructional videos will be developed on the in-class lesson contents on Robotics. Furthermore, robotics championship contests will be organized to engage the kids in productive teamwork towards creative intellectual learning.   "/>
        < ImageText title="3-D PRINTING" 
          imageURL="https://slack-imgs.com/?c=1&o1=ro&url=https%3A%2F%2Fimages.unsplash.com%2Fphoto-1563520239648-a24e51d4b570%3Fixlib%3Drb-1.2.1%26q%3D80%26fm%3Djpg%26crop%3Dentropy%26cs%3Dtinysrgb%26w%3D1080%26fit%3Dmax%26ixid%3DeyJhcHBfaWQiOjEyMDd9" 
          text="Learn Computer Aided Design (CAD). Become innovative and creative. Learn to formulate design problems. Generate your own model. Learn 3D Printing of your own model."
          readMore="3-D Printing: Comprehensive lessons on 3-D printing with its basic principles and operational procedure will be provided to the kids. In addition, hands-on-training on the operation of 3-D printer will be provided. After that, kids will be able to design their own models using computer-aided design (CAD) and print them by themselves. Moreover, instructional booklets and videos with detailed procedure of designing and printing 3-D models suited to the understanding of kids will be prepared. Also, 3-D printing contests among groups of kids with different expertise levels will be held monthly to encourage creative and advanced learning among them." />
          
           {/* < Asd  title="ELECTRONIC PROJECTS" 
          imageURL="https://i.imgur.com/PkRnUM3.jpg" 
          text="Learn about electronics and electricity basics Learn about multi-meter for electric measurement Learn how to use LED, micro-processor Learn how to control motors Learn in a project-based group assignment"
          readMore=" "
          />  */}

      
          < TextImage title="SCIENCE" 
          imageURL="https://images.unsplash.com/photo-1575503802870-45de6a6217c8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2100&q=80" 
          text="Expose kids into physics/chemistry lab. For physics lab works on magnetism, sound, light, electromagnetism. For chemistry lab works on chemical reactions, crystal formation, microscopic observation, gel electrophoresis. " 
          readMore="Physics/Chemistry: Cool and contemporary physics and chemistry projects are designed to attract kids to engineering, science, and technology. Multiple projects will be developed where the required tools, chemicals, other related materials will be provided so that each project can be completed. For each case, step-by-step procedures will be shown using pictures and videos to make a clear understanding."/>  
          
          
    </Container>
    

       




    </div>


   
    )
  }

}