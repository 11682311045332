import React from "react";
import MoreContentImage from "../components/moreContentImage";
import TextInImageThree from "../components/textInImageThree";
//import ImageText from "../components/imageText";
import Fees from "../components/fees";
import ProgHigh from "../components/progHigh";
import { Container } from "@material-ui/core";
import Footer from "../components/footer";


export default class MorePage extends React.Component{
  render(){
    return(
      <div >
        <div className="backgroundOne">
        < MoreContentImage /> 
        < TextInImageThree /> 
        </div>

    <Container>
        < ProgHigh title="PROGRAM HIGHLIGHTS" 
          imageURL="https://images.pexels.com/photos/4144222/pexels-photo-4144222.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" 
          /> 
           < Fees title="feesformat" title="FEES AND ENROLLMENT" 
          imageURL="https://images.pexels.com/photos/50987/money-card-business-credit-card-50987.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
          /> 
          
          
          
    </Container>
    

        




    </div>


   
    )
  }

}