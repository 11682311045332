import React from "react";


export default function TextInImage(){
    return(
        <>
                <div className="textWithinSmall">
            Learning Is Fun and Knowledge Is Power
        </div>
        <div className="textWithin">
            KIDS ADVANCED TECHNOLOGY CLUB
        </div>


        </>
    )
}