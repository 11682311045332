import React from "react";
import AboutContentImg from "../components/aboutContentImg";
import TextInImageFour from "../components/textInImageFour";
import ImageText from "../components/imageText";
import TextImage from "../components/textImage";
import ImageTextAbout from "../components/imageTextAbout";
import Asd from "../components/elecproj";
import { Container } from "@material-ui/core";
import Footer from "../components/footer";
import anjuman from "../images/anjuman.png"
import ian from "../images/ian.png"
import jahangir from "../images/jahangir.png"
import khalad from "../images/khalad.png"
import shahria from "../images/shahriar.png"
import ying from "../images/ying.png"
export default class About extends React.Component{
  render(){
    return(
      <div >
        <div className="backgroundOne">
        < AboutContentImg /> 
        < TextInImageFour /> 
        </div>

    <Container>

    < ImageTextAbout title="Dr. Anjuman Shahriar" 
          imageURL={anjuman}
          text="Engineering Supervisor, Transmission Lines (Electric) at FortisBC"
          academy="Kelowna, British Columbia, Canada" 
          readMore="https://www.linkedin.com/in/anjuman-shahriar-p-eng-4830312b/?originalSubdomain=ca"/>         
    
    < ImageTextAbout title="Dr. Ian Foulds" 
          imageURL={ian}
           text="Associate Professor"
          academy="Electrical Engineering, The University of British Columbia" 
          readMore="https://engineering.ok.ubc.ca/about/contact/ian-foulds/"/>  
    
    < ImageTextAbout title="Dr. Jahangir Hossain" 
          imageURL={jahangir}
           text="Professor"
          academy="Electrical Engineering, The University of British Columbia" 
          readMore="https://engineering.ok.ubc.ca/about/contact/jahangir-hossain/"/>  

    < ImageTextAbout title="Dr. Mohammad Khalad Hasan" 
          imageURL={khalad}
          text="Assistant Professor"
          academy="Computer Science, Data Science, The University of British Columbia" 
          readMore="https://cmps.ok.ubc.ca/about/contact/mohammad-khalad-hasan/"/>  

    < ImageTextAbout title="Dr. Shahria Alam" 
          imageURL={shahria}
          text="Professor"
          academy="Civil Engineering, The University of British Columbia" 
          readMore="https://engineering.ok.ubc.ca/about/contact/shahria-alam/"/>  

    < ImageTextAbout title="Dr. Ying Zhu" 
          imageURL={ying}
          text="Assistant Professor"
          academy="Faculty of Management, The University of British Columbia" 
          readMore="https://management.ok.ubc.ca/about/contact/ying-zhu/"/> 

          
          
    </Container>
    

       




    </div>


   
    )
  }

}