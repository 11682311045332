import React from "react";


export default function TextInImageThree(){
    return(
        <>
               
        <p className="textWithin">
            Program Highlights and Fees
        </p>


        </>
    )
}