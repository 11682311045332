import React from "react";


export default function CompTextWithin(props){

    return(
        <p className="textWithin">
            {props.title}
        </p>
    )
}