import React, { useState, useEffect } from 'react';
import '../stylesheets/components.css';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AOS from 'aos';
// import Toggle from './toggleButton';
import 'aos/dist/aos.css';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


const useStyles = makeStyles((theme) => ({
	modal: {
	  display: 'flex',
	  alignItems: 'center',
	  justifyContent: 'center',
	},
	paper: {
	  backgroundColor: theme.palette.background.paper,
	  boxShadow: theme.shadows[5],
	  padding: theme.spacing(2, 4, 3),
	  borderRadius: '10px'
	},
  }));


export default function TextImage(props) {
	useEffect(() => {
		AOS.init();
		AOS.refresh();
	}, []);

	const classes = useStyles();

	const [open, setOpen] = useState(false);


	const handleOpen = () => {
		setOpen(true);
	  };
	
	  const handleClose = () => {
		setOpen(false);
	  };

	return (
		<Grid container className="spacing extra" spacing={6}>
			<Grid item md={6} xs={12} className="first">
				<div class="titleText">{props.title}</div>
				<div class="subtitleText">{props.text}</div>
				<div>
					<Button
						variant="contained"
						color="primary"
						className="buttonIntro"
						onClick={handleOpen}
						endIcon={<KeyboardArrowRightIcon />}
					>
						Read More
					</Button>

				</div>
			</Grid>

			<Grid item md={6} xs={12} className="second">
				<video className="videoText" autoPlay playsInline muted loop data-aos="fade-up">
					<source src="https://compilelabs.s3.ca-central-1.amazonaws.com/kat-club/new.mp4" type="video/mp4" />
				</video>
			</Grid>

			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				className={`${classes.modal} system1`}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
				timeout: 500,
				}}
			>
				<Fade in={open}>
				<div className={`${classes.paper} paper1`}>
					<h2 id="transition-modal-title">Introduction</h2>
					<p id="transition-modal-description">
						<div class='text1'>
							<ul>• Prepare our next generation for the next big thing</ul>
							<ul>• Unleash the child’s potential </ul>
							<ul>• Educate them about the latest technologies and trends</ul>
							<ul>• Exposure to new technology</ul>
							<ul>•Inspiring kids and challenge their potentials </ul>
						</div>
					</p>
				</div>
				</Fade>
			</Modal>
		
		</Grid>
	);
}
