import React, { useState, useEffect } from 'react'
import '../stylesheets/components.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import AOS from "aos";
import "aos/dist/aos.css";

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
export default function Fees(props){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
    return(
   
            <Grid container className="spacing" spacing={6}>
       
                
                <Grid item xs={12} md={6}>
                    <div class="titleText">{props.title}</div>
                    <div class="subtitleText">
                        <b class = "fee">FEES</b>
                        <div class='text1'>
                        <br></br>
                        For Fees information please Email us at <a href="mailto:support@katclub.ca">support@katclub.ca</a> or Call us at <br></br>
                        <a href="tel:250-212-9200">250-212-9200</a> , <a href="tel:778-801-9201">778-801-9201</a> , <a href="tel:778-807-7017">778-807-7017</a>
                        </div>
                        {/* <ul class = "level"> <u>Level 1</u>
                        <br></br>
                        <p>One Module: $$$, Two Modules: $$$, Three Modules: $$$, Four Modules: $$$, Five Modules: $$$ </p>
                        </ul>
                        <ul class = "level"> <u>Level 2</u>
                        <br></br>
                        <p>One Module: $$$, Two Modules: $$$, Three Modules: $$$, Four Modules: $$$, Five Modules: $$$ </p>
                        </ul>
                        <ul class = "level"> <u>Level 3</u>
                        <br></br>
                        <p>One Module: $$$, Two Modules: $$$, Three Modules: $$$, Four Modules: $$$, Five Modules: $$$ </p>
                        </ul> */}
                        <br></br>
                        <b class = "fee">ENROLLMENT</b>
                        <div class='text1'>
                        <br></br>
                        For Enrollment information please Email us at <a href="mailto:support@katclub.ca">support@katclub.ca</a> <br></br> or Call us at <a href="tel:250-212-9200">250-212-9200</a> , <a href="tel:778-801-9201">778-801-9201</a> , <a href="tel:778-807-7017">778-807-7017</a>  <br></br> or Register <a href="/registration/weekday">here</a>,
                        we will get back to you soon.
                        </div>

                    </div>
                   
                </Grid>

                <Grid item xs={12} md={6}>
                    <img src={props.imageURL} className="imageText2" data-aos="fade-up"/>
                </Grid>
            </Grid>
      
    )
}