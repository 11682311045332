import React, { useState, useEffect } from 'react'
import '../stylesheets/components.css'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';

import AOS from "aos";
import "aos/dist/aos.css";

import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


const useStyles = makeStyles((theme) => ({
	modal: {
	  display: 'flex',
	  alignItems: 'center',
	  justifyContent: 'center',

	},
	paper: {
	  backgroundColor: theme.palette.background.paper,
	  boxShadow: theme.shadows[5],
	  padding: theme.spacing(2, 4, 3),

	  borderRadius: '10px'
	},
  }));




export default function ImageText(props){
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
    
	const classes = useStyles();

	const [open, setOpen] = useState(false);


	const handleOpen = () => {
		setOpen(true);
	};
	
    const handleClose = () => {
        setOpen(false);
    };
    


    return(
   
            <Grid container className="spacing" spacing={6}>
                <Grid item md={6} xs={12}>
        

                    <video  className="videoText" autoPlay playsInline muted loop data-aos="fade-up">
                        <source src="https://compilelabs.s3.ca-central-1.amazonaws.com/kat-club/1.21.mp4" type="video/mp4" />
                    </video>
                </Grid>
                
                <Grid item md={6} xs={12}>
                    <div class="titleText">{props.title}</div>
                    <div class="subtitleText">{props.text}</div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="buttonIntro"
                        onClick={handleOpen}
                        endIcon={<KeyboardArrowRightIcon/>}
                    >
                        Read More
                    </Button>

                </Grid>



                <Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				className={`${classes.modal} system1`}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
				timeout: 500,
				}}
			>
				<Fade in={open}>
				<div className={`${classes.paper} paper1`}>
					<h2 id="transition-modal-title">Objectives</h2>
					<p id="transition-modal-description">
                        <div class='text1'>
                            KAT Club trains school kids from elementary to high school with the latest technologies that include coding, 3D printing, electronics, robotics, physics/chemistry in a structured way. The kids are trained through project-based learning. We have developed multiple modules and packages for beginners, intermediate, and expert levels. The lectures are delivered to each student on a one-to-one basis and all the lectures are recorded for multiple views so that the students can access and learn the subject matter in depth. 
                        </div>
					</p>
				</div>
				</Fade>
			</Modal>
            </Grid>
      
    )
}