import React, {useState, useEffect, useRef} from 'react';
import { withRouter } from 'react-router';
import { Container } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { SentimentSatisfied } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import RegisImage from "../components/RegisImage"
import CompTextWithin from "../components/textWithinComp"
import NavigatorForm from '../components/navigatorForm'


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }


  var Recaptcha = require('react-recaptcha');



const ContactForm = (props) => {



    const [open, setOpen] = useState(false);


    const buttonStyle = {
        marginTop: "20px",
        backgroundColor: "rgb(20, 150, 255)",
        outline: "none",
        padding: "10px",
        fontWeight: "500",
        color: "white"
    }

    const initialValues = {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        studentSchool: '',
        notes: '',

        module1: false,
        module2: false,
        module3: false,
        module4: false,
    }


   

    const handleClick = () => {
        setOpen(true);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

    var [values, setValues] = useState(initialValues)

    var [capchca, setCap] = useState(true);


    var [able, setAble] = useState(true);

    const handleChange = e => {
        var { name, value } = e.target;
        setValues({
            ...values, 
            [name]: value,
        })




    }


    var callback = function () {
        console.log('Done!!!!');
    };


    var verifyCallback = function () {
        console.log("Varified");
        setCap(false);
  

    };







    const handleSubmit = e => {
       
            const templateId = 'template_q5xrcux';
            e.preventDefault();
            handleClick();
            // props.sendFeedback(templateId, {message_html: values.message, module_one: values.module1, module_two: values.module2, module_three: values.module3 , module_four: values.module4, phone_number: values.phone, student_grade: values.studentSchool, from_firstName: values.firstName, from_lastName: values.lastName, from_email: values.email, additional_info_notes: values.notes });
            props.sendFeedback(templateId, {phone_number: values.phone, from_firstName: values.firstName, from_lastName: values.lastName, from_email: values.email, additional_info_notes: values.notes });
            setValues({...initialValues});
            e.target.reset();
    
    }

    // const [ isVerified, setVerified ] = useState(false);

    // this.verifyUser = this.verifyUser.bind('this');

    // const verifyUser = () => {
    //     if(values.robot == true) {
    //         alert('Please verify that you are a human');
            
    //     }
    //     else{
    //         alert('We have successfully recorded your information. Thank you');
    //     }
    // }


    return(
        <>         
        <div  className="formArea">
            
            <Container>
                
                <form onSubmit={handleSubmit} className="formHere">
                <h4>Registration Form</h4>
                <br></br><br></br>
                  <Grid container>
                    <Grid item md={6} xs={12} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}> First Name</label>
                        <input type="text" id="fname" name="firstName" placeholder="Student's First Name..."
                            value={values.firstName}
                            onChange={handleChange}
                        />
                    </Grid >

                    <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}> Last Name</label>
                        <input type="text" id="fname" name="lastName" placeholder="Student's Last Name..."
                            value={values.lastName}
                            onChange={handleChange}
                        />
                    </Grid >


                    <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}> Email Address</label>
                        <input type="email" id="email" name="email" placeholder="Student's Email..."
                            value={values.email}
                            onChange={handleChange}
                        />
                     </Grid >


                    <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Phone Number</label>
                        <input type="tel" id="phone" name="phone" placeholder="Phone Number..."
                            value={values.phone}
                            onChange={handleChange}
                        />
                     </Grid >

                     {/* <Grid item xs={6} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Grade</label>
                        <input type="number" id="studentSchool" name="studentSchool" placeholder="Student's Grade..."
                            value={values.studentSchool}
                            onChange={handleChange}
                            min="1" max="12"
                        />
                     </Grid > */}

                     <Grid item md={6} xs={12} className="formItem">
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Note: Preferred time and any additional info</label>
                        <textarea type="text" cols="40" rows="4" id="notes" name="notes" placeholder="Student's Preferred time and any additional info..."
                            value={values.notes}
                            onChange={handleChange}
                        />
                     </Grid >

                     {/* <Grid item md={6} xs={12} className="formItem">
                     <br></br>
                        <label style={{fontWeight: "900", fontSize: "12px"}}>Check Your Preferred Modules</label>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module1" name="module1" onChange={handleChange} value={true}/>
                            <label for="module1"  className="labelCheck">Module 1: Coding </label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module2" name="module2" onChange={handleChange} value={true}/>
                            <label for="module2" className="labelCheck">Module 2: 3D printing </label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module3" name="module3" onChange={handleChange} value={true}/>
                            <label for="module3"  className="labelCheck">Module 3: Fun Math </label>
                        </div>
                        <div style={{marginTop: "10px"}}>
                            <input type="checkbox" id="module4" name="module4" onChange={handleChange} value={true}/>
                            <label for="module4"  className="labelCheck">Module 4: Robotics </label>
                        </div>
              
               
                     </Grid > */}


        
                    <Grid item xs={12} style={{padding: "20px"}}>
       


                        <Recaptcha
                            sitekey="6Le6PCgaAAAAAHzDlcsP-0TjLp0aiLyW5ANq7TLy"
                            render="explicit"
                            onloadCallback={callback}
                            verifyCallback={verifyCallback}
                        />
                        <br></br><br></br>
                        <Button variant="contained" disabled={(values.lastName && values.firstName && values.email && capchca == false) ? false : true} type="submit"  style={buttonStyle} >
                            Submit
                        </Button>
                        
                    </Grid>
                    </Grid>
                   
                </form >
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Thank you for registering!
                    </Alert>
                </Snackbar>
            </Container>
        </div>
        </>

    );
}

export default withRouter(ContactForm);